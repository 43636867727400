<template>
	<div class="w-screen  overflow-x-hidden" id="#app">
		<NavBar></NavBar>
        <Application></Application>
		<Footer></Footer>
	</div>
</template>

<script>
import NavBar from "../components/NavBar.vue";

import Footer from "../components/Footer.vue";
import Application from "../components/ScaricaApp";

export default {
	name: "Bonuses",
	components: {
		NavBar,
        Application,
		Footer
	}
};
</script>

<style>
</style>