<template>
    <div class="w-screen h-auto bg-white items-center pl-0 lg:pl-28  py-0 lg:py-0 md:mt-10 flex space-y-12 flex-col lg:flex-row overflow-hidden">
        <div id='left' class=" flex  flex-1 flex-col w-full items-start md:items-center lg:items-start space-y-8 px-8 md:px-16 lg:px-0 py-8 lg:py-0">
            <div id='container' class='flex flex-col flex-1  items-start md:items-center lg:items-start align-middle justify-items-center w-full'>
                <h1 class="text-6xl font-bold text-black hidden md:block">Benvenuto in </h1>
                <h1 id="PLTNVMEXPE" class='text-6xl font-bold font-Montserrat bg-clip-text text-transparent bg-gradient-to-r from-sky-500 to-indigo-500'>PLTNVM EXPERIENCE</h1>

                <p class='mt-8'>Il progetto PLTNVM non è solo una piattaforma di generazione pronostici, è un metodo per chi vuole raggiungere risultati nel lungo termine, con un approccio basato su dati, disciplina e gestione del budget. Il nostro obiettivo è vincere, non con la fortuna, ma con strategia e consapevolezza.</p>
            </div>
            <img src="../assets/divieto.png" style="height:80px;"/>
            <a href="experience/" id="buttone" class="flex-grow-0 max-w-fit rounded-lg bg-gradient-to-r from-sky-500 to-indigo-500 text-white py-4 px-6 text-base buttone" ><b>ACCEDI O REGISTRATI</b></a>
            <a href="/#/results" id="buttone" class="flex-grow-0 max-w-fit rounded-lg bg-gradient-to-r from-lime-400 to-green-500 text-white py-4 px-6 text-base buttone12" ><b>SEZIONE STATISTICHE</b></a>
            <div style="display:flex; justify-content: space-between; align-items: center; gap: 20px;">
                <a href="/#/scarica-app"> <img src="../assets/scarica.png" style="height:100px;"/></a>
            <a href="https://www.trustpilot.com/review/www.pltnvmexperience.com"> <img class="stelline" src="../assets/trustpilot.png"></a>
        </div>
        
    </div>
       
        <div id='right' class="flex-1 w-full mt-10 lg:mt-0">
            <img src="../assets/Dashboard.png" class="hidden lg:flex flex-1 flex-grow w-full"/>
            <img src="../assets/dashboard-mobile.png" class="flex lg:hidden flex-1 flex-grow w-full"/>
            

        </div>
        
            
    </div>
    
</template>

<script>
export default {
    name : "Hero",


}
</script>

<style>
    .stelline{
        width: 200px;

    }
    @media only screen and (max-width: 425px) {
        #PLTNVMEXPE{
            font-size: 40px;
            text-align: center;
        }
    }

    .buttone12 {
        cursor: pointer;
        font-family: "Inter";
        font-style: normal;
    }
    .buttone12::before{
        content: "";
        position:absolute;
        background: white;
        transform: rotate(-16deg);
        filter:blur(40px);
        width: 8px;
        top: 0;
        bottom: 0;
        left:-32px;



    }
    .buttone12:hover::before{
        left:calc(100% + 32px);
        transition: 0.75s;
    }
    .buttone {
        cursor: pointer;
        font-family: "Inter";
    }
    .buttone::before{
        content: "";
        position:absolute;
        background: white;
        transform: rotate(-16deg);
        filter:blur(40px);
        width: 8px;
        top: 0;
        bottom: 0;
        left:-32px;



    }
    .buttone:hover::before{
        left:calc(100% + 32px);
        transition: 0.75s;
    }
</style>