<template >
	<div id="subscriptions" class="w-screen min-h-screen flex flex-col bg-[#2B43E00D] items-center p-8 lg:py-20 lg:px-28 space-y-12">
		<h1 class="text-4xl font-bold leading-10">Inizia la tua Experience</h1>
		<div id="plans" class="flex flex-col lg:flex-row min-h-[80vh] w-full lg:space-x-8 gap-y-8 ">
			<div id="container1" class="bg-white shadow-xl w-full my-3 rounded-2xl flex flex-col md:flex-row lg:flex-col justify-between items-center gap-y-8 py-10 md:py-6 lg:py-10 px-10 md:px-6 lg:px-10">
				<img src="../assets/newlogoazzurro.svg" class="md:hidden lg:block"/>
				<div class="flex flex-col items-center justify-between gap-y-8 md:gap-y-0 lg:gap-y-8">
					<h1 class='text-black text-3xl md:text-sm lg:text-3xl text-center md:text-left w-full font-bold'>PASS 7 GIORNI</h1>
					<p class='rounded-3xl bg-[#2B43E01A] py-2 w-full text-center text-xs font-bold md:hidden lg:block'>7 giorni di prova gratuita</p>
					<div id="prices" class= 'space-y-2 flex flex-col items-center md:items-left lg:items-center w-full'>

						<p class='md:text-2xl text-4xl lg:text-4xl font-bold w-full text-center md:text-left lg:text-center'>GRATIS</p>
					</div>
				</div>
				<ul class="coa">
    <li>✔️ Accesso al materiale completo</li>
    <li>✔️ Software Sport fino a 5 pronostici</li>
    <li>❌ Software Roulette</li>
    <li>❌ Money Manager statistiche</li>
	<li>❌ Accesso alla Commmunity</li>
	<li>✔️ Assistenza diretta</li>
	<br>
	<p style="color:grey">Terminato il periodo di prova, il pass verrà automaticamente aggiornato al piano mensile, è possibile disdire in qualsiasi momento. </p>
</ul>
				<button id="buy" @click="load('https://buy.stripe.com/7sI6qZ2il3F9fXWcMU')" class='rounded-lg bg-gradient-to-r from-sky-500 to-indigo-500 py-4 px-6 text-white font-semibold text-base cursor-pointer buttone'>Inizia subito</button>
			</div>
			<div id="container2" class="bg-gradient-to-r from-sky-500 to-indigo-500 shadow-xl w-full rounded-2xl flex flex-col md:flex-row lg:flex-col justify-between items-center gap-y-8 py-10 md:py-6 lg:py-10 px-10 md:px-6 lg:px-10">
				<img src="../assets/Logobianco.svg" class="md:hidden lg:block"/>
					<div class="flex flex-col items-center gap-y-8 md:gap-y-0 lg:gap-y-8">
						<h1 class='text-white text-3xl md:text-sm lg:text-3xl text-center md:text-left w-full font-bold'>PASS ANNUALE</h1>
						<p class='rounded-3xl bg-[#FFFFFF] bg-opacity-20 py-2 w-full text-center text-xs font-bold text-white md:hidden lg:block'>Tutta la stagione</p>
						<div id="prices" class= 'space-y-2 flex flex-col items-center md:items-left lg:items-center w-full'>
							<p class='md:text-xs text-lg lg:text-lg font-medium line-through w-full text-center md:text-left lg:text-center text-white '>€199,90</p>
							<p class='md:text-2xl text-4xl lg:text-4xl font-bold w-full text-center md:text-left lg:text-center text-white'>€99,90/anno</p>
						</div>
					</div>
					<ul class="coapro" style="color:white;">
    <li>✅ Accesso al materiale completo</li>
    <li>✅ Software Sport fino a 5 pronostici</li>
    <li>✅ Software Roulette</li>
    <li>✅ Money Manager statistiche</li>
	<li>✅ Accesso alla Commmunity</li>
	<li>✅ Assistenza diretta</li>
</ul>
				<button id="buy" @click="load('https://buy.stripe.com/9AQcPne135Nh132cMT')" class='rounded-lg bg-white py-4 px-6 text-indigo-500 font-semibold text-base cursor-pointer buttone'>Inizia ora</button>

			</div>
			<div id="container3" class="bg-white shadow-xl w-full my-3 rounded-2xl flex flex-col md:flex-row lg:flex-col justify-between items-center gap-y-8 py-10 md:py-6 lg:py-10 px-10 md:px-6 lg:px-10">
						<img src="../assets/newlogoazzurro.svg" class="md:hidden lg:block"/>
						<div class="flex flex-col items-center justify-between gap-y-8 md:gap-y-0 lg:gap-y-8">
							<h1 class='text-3xl md:text-sm lg:text-3xl md:text-left w-full font-bold text-center'>PASS MENSILE</h1>
							<p class='rounded-3xl bg-[#2B43E0] text-black bg-opacity-10 py-2 px-4 w-full text-center text-xs font-bold md:hidden lg:block'>30 giorni di fuoco</p>
							<div id="prices" class= 'space-y-2 flex flex-col items-center md:items-left lg:items-center w-full'>
									<p class='md:text-xs text-lg lg:text-lg font-medium line-through w-full text-center md:text-left lg:text-center'><br></p>
									<p class='md:text-2xl text-4xl lg:text-4xl font-bold w-full text-center md:text-left lg:text-center'>€19,90/mese</p>
							</div>
						</div>
						<ul class="coa">
    <li>✔️ Accesso al materiale completo</li>
    <li>✔️ Software Sport fino a 5 pronostici</li>
    <li>✔️ Software Roulette</li>
    <li>❌ Money Manager statistiche</li>
	<li>✔️ Accesso alla Commmunity</li>
	<li>✔️ Assistenza diretta</li>
</ul>
						<button id="buy" @click="load('https://buy.stripe.com/4gwg1ze13a3x9zy3ci')" class='rounded-lg bg-gradient-to-r from-sky-500 to-indigo-500 py-4 px-6 text-white font-semibold text-base cursor-pointer buttone'>Inizia subito</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
methods: {
    load(url){
        window.open(url,'_blank')
    }
}
};
</script>

<style>
</style>
