import '../assets/stats.css';
import platinumLogoRoulette from '../../src/assets/logoRoulettePLTNVM.png';
import platinumLogoSport from '../../src/assets/logoSportPLTNVM.png';
import platinumLogoMoney from '../../src/assets/logoMoneyManager.png';


const Sales = () => {
  return (
    <div className="p-6">
      <h1 className="text-4xl md:text-6xl font-bold font-Montserrat bg-clip-text text-transparent bg-gradient-to-r from-sky-500 to-indigo-500 text-center">
        INIZIA LA TUA<br /> PLTNVM EXPERIENCE
      </h1>
      
      <p className="px-4 md:px-12 py-5 text-center text-gray-800 text-base md:text-lg">
        Le scommesse non sono solo fortuna: sono strategia, disciplina e gestione intelligente del bankroll.
      </p>

      {/* Video Container */}
      <div className="flex justify-center mt-8 px-4">
        <iframe
          className="w-full max-w-3xl h-48 sm:h-64 md:h-96"
          src="https://www.youtube.com/embed/qfs-ggA4PQg"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <ul className="flex flex-col items-center justify-center text-lg list-none p-0 mt-6 space-y-4">
        <li className="text-center">🎰 Scommetti sulle sequenze generate dal nostro <strong>Software Roulette</strong></li>
        <li className="text-center">🚀 Genera oltre 5 pronostici al giorno con il nostro <strong>Software Sport</strong></li>
        <li className="text-center">🎯 Gestisci il tuo capitale con il <strong>Money Manager</strong></li>
      </ul>

      {/* Pulsanti */}
      <div className="px-4 flex flex-col md:flex-row justify-center items-center gap-4 mt-8">
        <button 
          onClick={() => window.open('https://buy.stripe.com/4gwg1ze13a3x9zy3ci', '_blank')}
          className="bg-sky-500 text-white py-4 px-8 w-full md:w-auto rounded-lg font-bold text-sm md:text-base hover:bg-sky-600 transition-colors"
        >
          PASS MENSILE<br />(€19.90/mese)
        </button>
        
        <button 
          onClick={() => window.open('https://buy.stripe.com/9AQcPne135Nh132cMT', '_blank')}
          className="bg-sky-500 text-white py-4 px-8 w-full md:w-auto rounded-lg font-bold text-sm md:text-base hover:bg-sky-600 transition-colors"
        >
          PASS ANNUALE<br />(€99.90/anno)
        </button>
        
      
      </div>

      <h1 className="text-4xl md:text-6xl font-bold font-Montserrat bg-clip-text text-transparent bg-gradient-to-r from-sky-500 to-indigo-500 text-center mt-12">
        TUTTO IN UN UNICO POSTO
      </h1>
      
      <p className="px-4 md:px-12 py-5 text-center text-gray-800 text-base md:text-lg">
        Inizia subito il tuo percorso di gioco, consapevole e costante, nella nostra piattaforma EXPERIENCE. <br className="hidden md:block" /> 
        All'interno potrai trovare: 
      </p>

   {/* Card Container */}
<div className="px-4 md:px-0">
  <div className="flex flex-col md:flex-row items-center md:items-stretch justify-center gap-6 m-8">
    {/* Card 1 */}
    <div className="bg-gradient-to-r from-sky-500 to-indigo-500 shadow-xl w-full md:w-1/3 rounded-2xl flex flex-col justify-between items-center gap-y-4 py-8 px-6 h-full">
      <img src={platinumLogoRoulette} alt="PLATINUM Roulette Logo" className="h-40" />
      <h1 className="text-white text-xl md:text-2xl font-bold text-center">Software Roulette</h1>
      <p className="text-white text-sm md:text-base text-center px-2 mb-4 flex-1">
        Ottimizza le tue strategie di gioco con il nostro software avanzato per la roulette. 
        Analizza pattern e migliora le tue probabilità di successo basandoti su dati concreti.
      </p>
    </div>

    {/* Card 2 */}
    <div className="bg-gradient-to-r from-sky-500 to-indigo-500 shadow-xl w-full md:w-1/3 rounded-2xl flex flex-col justify-between items-center gap-y-4 py-8 px-6 h-full">
      <img src={platinumLogoSport} alt="PLATINUM Roulette Logo" className="h-40" />
      <h1 className="text-white text-xl md:text-2xl font-bold text-center">Software Sport</h1>
      <p className="text-white text-sm md:text-base text-center px-2 mb-4 flex-1">
        Genera pronostici sportivi intelligenti con il nostro sistema algoritmico. 
        Analisi in tempo reale e suggerimenti basati su dati statistici.
      </p>
    </div>

    {/* Card 3 */}
    <div className="bg-gradient-to-r from-sky-500 to-indigo-500 shadow-xl w-full md:w-1/3 rounded-2xl flex flex-col justify-between items-center gap-y-4 py-8 px-6 h-full">
      <img src={platinumLogoMoney} alt="PLATINUM Roulette Logo" className="h-40" />
      <h1 className="text-white text-xl md:text-2xl font-bold text-center">Money Manager</h1>
      <p className="text-white text-sm md:text-base text-center px-2 mb-4 flex-1">
        Sistema avanzato per la gestione del bankroll. 
        Monitora le tue performance e ottimizza le tue puntate con strumenti professionali.
      </p>
    </div>
  </div>
</div>
    </div>
  );
};

export default Sales;