<template>
    <div class="bg-gradient-to-r from-sky-500 to-indigo-500 w-screen flex flex-col lg:flex-row p-8 lg:px-20 lg:py-16 justify-between">
        <div class="flex flex-col flex-1">
            <div id="links" class="flex flex-row flex-wrap mb-6 lg:mb-20">
                <img src="../assets/Logobianco.svg" class="mb-6">
                <div class="w-full flex flex-row space-x-3 lg:space-x-0">
                    <h1 class="text-white font-semibold text-base  lg:mb-6">Link rapidi</h1>
   
                </div>

                <!-- <div class="w-full hidden lg:flex flex-row space-x-6 mb-3">
                    <p class="text-white text-center cursor-pointer">PLTNVM EXPERIENCE</p>
                    <p class="text-white text-center cursor-pointer">Come funziona</p>
                    <p class="text-white text-center cursor-pointer">I nostri softwares</p>
                </div> -->
               
            </div>
            <div class="flex flex-col space-y-6 mb-6 lg:mb-0">
                
                <p class="text-white font-normal text-base ">P.IVA 02667420745<br><br>Brindisi, 72100, Italia<br><br>ceo@pltnvmexperience.com</p>
            </div>
        </div>
        <div class="flex flex-col w-full flex-1 space-y-2">
            <h1 class="text-white font-semibold text-base"> Disclaimer</h1>
            <p class="text-white font-normal text-sm">Il gioco é vietato ai minori di 18 anni e può causare dipendenza patologica.<br><br>Le analisi e i pronostici presenti all’interno del sito sono frutto di opinioni personali. Gli eventi sportivi proposti dall’autore del sito sono scelti a discrezione dell’autore del sito. Gli autori s’impegnano a fornire dati accurati e aggiornati ma non offrono alcuna garanzia assoluta circa l’accuratezza o l’attualità del contenuto del sito, come non si assume alcuna responsabilità per eventuali errori o omissioni. I siti di scommesse comparati all’interno del sito sono in possesso di regolare concessione ad operare in Italia rilasciata dall’ Agenzia delle Dogane e dei Monopoli.</p>
            <h1 class="text-white font-semibold text-base">Metodi di pagamento</h1>
            <div class="flex flex-row w-full space-x-2">
                <img src='../assets/payment-methods/visa.svg'>
                <img src='../assets/payment-methods/mastercard.svg'>
                <img src='../assets/payment-methods/american-express.svg'>
                <img src='../assets/payment-methods/maestro.svg'>
                

            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>