import '../assets/stats.css';

const Statistiche = () => {
  return (
    <div style={{ height: "auto", padding: "25px" }}>
      <h1 id="titolo2"><b>I NOSTRI RISULTATI</b></h1>
      <p id="paragrafo2" style={{ padding: "20px", textAlign: "left", color: "#202020" }}>
        Uno dei nostri strumenti più efficaci è il <b>Money Manager</b>, che ci permette di avere una visione chiara e trasparente dei nostri investimenti sul lungo periodo. 
        I nostri risultati sono pubblici e consultabili direttamente attraverso il bottone qui sotto, che vi rimanderà al nostro Money Manager.
      </p>
      
      {/* Bottone per aprire Google Sheets in una nuova finestra */}
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "20px" }}>
       
      </div>

      {/* Embed Google Sheets nel sito */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <iframe
          src="https://docs.google.com/spreadsheets/d/1qcKtAOaKUxOW84YBn6pqByBjhxiDS5tekJWg3NvkL8A/edit?gid=1469907196#gid=1469907196"
          width="100%"
          height="1000px"
          style={{ border: "none" }}
          title="Google Sheets"
        ></iframe>
      </div>
    </div>
  );
};

export default Statistiche;
