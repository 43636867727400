<template>
	<div class="w-screen  overflow-x-hidden" id="#app">
		<NavBar></NavBar>
        <Subscriptions></Subscriptions>
		<Footer></Footer>
	</div>
</template>

<script>
import NavBar from "../components/NavBar.vue";
import Subscriptions from "../components/Subscriptions.vue";
import Footer from "../components/Footer.vue";

export default {
	name: "Bonuses",
	components: {
		NavBar,
        Subscriptions,
		Footer
	}
};
</script>

<style>
</style>