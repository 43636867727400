<template>
	<div></div>

</template>

<script>
export default {
  data(){
    return{
     expand : false
    }
  }
}
</script>

<style>

@media(min-width: 1281px){
	.jello{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.hellobaby{
		margin-top: 15px;
		font-family: "Inter";
		display:block;

	}
.main1{
	margin-top: 200px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 7rem;
	padding-left: 8%;
	padding-right: 8%;
}
.container{

padding: 20px;
border: grey;
border-radius: 20px;
margin-right: 30px;

-webkit-box-shadow:0px 0px 24px 0px #c0c0c0 ;
-moz-box-shadow:0px 0px 24px 0px #c0c0c0 ;
box-shadow:0px 0px 24px 0px #c0c0c0 ;
}
.container2{

	padding: 20px;
	border: grey;
	border-radius: 20px;
	margin-right: 30px;
	
-webkit-box-shadow:0px 0px 24px 0px #c0c0c0 ;
-moz-box-shadow:0px 0px 24px 0px #c0c0c0 ;
box-shadow:0px 0px 24px 0px #c0c0c0 ;
}
#descris{
	text-align: center;
	padding-bottom: 40px;
	font-family: 'Inter';
	font-size: 18px;
}
#titolot{
	font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 26px;
line-height: 35px;
text-align: center;
font-feature-settings: 'liga' off;
color: #000000;
padding-bottom: 2rem;
}


.slider-wrapper {
	position: relative;
	max-width: 28rem;
	margin: 0 auto;
  
}

.slider {
	display: flex;
	aspect-ratio: 16 / 9;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
	box-shadow: 0 1.5rem 3rem -0.75rem hsla(0, 0%, 0%, 0.25);
	border-radius: 0.5rem;
    overflow-x: scroll;
}

.slider img {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
}
#img7 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img1 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img2 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img3 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img4 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img5 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img6 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img7 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
.slider-nav {
	display: flex;
	column-gap: 1rem;
	position: absolute;
	bottom: 1.25rem;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}

.slider-nav a {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 50%;
	background-color: #fff;
	opacity: 0.75;
	transition: opacity ease 250ms;
}

.slider-nav a:hover {
	opacity: 1;
}
#img1 .btn {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #00415A;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.stats{
	margin-top: 25px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.statsimage{
	width: 60%;
}
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.jello{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.main1{
	margin-top: 200px;
	margin-bottom: 7rem;
	padding-left: 8%;
	padding-right: 8%;
	
}
.container{

padding: 20px;
border: grey;
border-radius: 20px;
margin-right: 30px;

-webkit-box-shadow:0px 0px 24px 0px #c0c0c0 ;
-moz-box-shadow:0px 0px 24px 0px #c0c0c0 ;
box-shadow:0px 0px 24px 0px #c0c0c0 ;
}
.container2{
	padding: 20px;
	border: grey;
	border-radius: 20px;
	margin-right: 30px;
	margin-bottom: 2.5rem;
	margin-left: 30px;
-webkit-box-shadow:0px 0px 24px 0px #c0c0c0 ;
-moz-box-shadow:0px 0px 24px 0px #c0c0c0 ;
box-shadow:0px 0px 24px 0px #c0c0c0 ;
}
#descris{
	text-align: center;
	padding-bottom: 40px;
	font-family: 'Inter';
	font-size: 18px;
}
#titolot{
	font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 26px;
line-height: 35px;
text-align: center;
font-feature-settings: 'liga' off;
color: #000000;
padding-bottom: 2rem;
}


.slider-wrapper {
	position: relative;
	max-width: 28rem;
	margin: 0 auto;
  
}

.slider {
	display: flex;
	aspect-ratio: 16 / 9;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
	box-shadow: 0 1.5rem 3rem -0.75rem hsla(0, 0%, 0%, 0.25);
	border-radius: 0.5rem;
    overflow-x: scroll;
}

.slider img {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
}
#img1 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img2 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img3 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img4 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img5 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img6 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img7 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
.slider-nav {
	display: flex;
	column-gap: 1rem;
	position: absolute;
	bottom: 1.25rem;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}

.slider-nav a {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 50%;
	background-color: #fff;
	opacity: 0.75;
	transition: opacity ease 250ms;
}

.slider-nav a:hover {
	opacity: 1;
}
#img1 .btn {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #00415A;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.stats{
	margin-top: 25px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.statsimage{
	width: 60%;
	padding: 15px;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	.main1{
	margin-top: 200px;
	margin-bottom: 7rem;
	padding-left: 8%;
	padding-right: 8%;
	
}
.container{

padding: 20px;
border: grey;
border-radius: 20px;
margin-right: 30px;

-webkit-box-shadow:0px 0px 24px 0px #c0c0c0 ;
-moz-box-shadow:0px 0px 24px 0px #c0c0c0 ;
box-shadow:0px 0px 24px 0px #c0c0c0 ;
}
.container2{
	padding: 20px;
	border: grey;
	border-radius: 20px;
	margin-right: 30px;
	margin-bottom: 2.5rem;
	margin-left: 30px;
-webkit-box-shadow:0px 0px 24px 0px #c0c0c0 ;
-moz-box-shadow:0px 0px 24px 0px #c0c0c0 ;
box-shadow:0px 0px 24px 0px #c0c0c0 ;
}
#descris{
	text-align: center;
	padding-bottom: 40px;
	font-family: 'Inter';
	font-size: 18px;
}
#titolot{
	font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 26px;
line-height: 35px;
text-align: center;
font-feature-settings: 'liga' off;
color: #000000;
padding-bottom: 2rem;
}


.slider-wrapper {
	position: relative;
	max-width: 28rem;
	margin: 0 auto;
  
}

.slider {
	display: flex;
	aspect-ratio: 16 / 9;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
	box-shadow: 0 1.5rem 3rem -0.75rem hsla(0, 0%, 0%, 0.25);
	border-radius: 0.5rem;
    overflow-x: scroll;
}

.slider img {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
}
#img1 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img2 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img3 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img4 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img5 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img6 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}#img7 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
.slider-nav {
	display: flex;
	column-gap: 1rem;
	position: absolute;
	bottom: 1.25rem;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}

.slider-nav a {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 50%;
	background-color: #fff;
	opacity: 0.75;
	transition: opacity ease 250ms;
}

.slider-nav a:hover {
	opacity: 1;
}
#img1 .btn {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #00415A;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.stats{
	margin-top: 25px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.statsimage{
	width: 60%;
}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	.main1{
	margin-top: 200px;
	margin-bottom: 7rem;
	padding-left: 8%;
	padding-right: 8%;
	
}
.container{

padding: 20px;
border: grey;
border-radius: 20px;
width: 80%;


-webkit-box-shadow:0px 0px 24px 0px #c0c0c0 ;
-moz-box-shadow:0px 0px 24px 0px #c0c0c0 ;
box-shadow:0px 0px 24px 0px #c0c0c0 ;
}
.container2{
	padding: 20px;
	border: grey;
	border-radius: 20px;
	margin-right: 30px;
	margin-bottom: 2.5rem;
	margin-left: 30px;
-webkit-box-shadow:0px 0px 24px 0px #c0c0c0 ;
-moz-box-shadow:0px 0px 24px 0px #c0c0c0 ;
box-shadow:0px 0px 24px 0px #c0c0c0 ;
}
#descris{
	text-align: center;
	padding-bottom: 40px;
	font-family: 'Inter';
	font-size: 18px;
}
#titolot{
	font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 26px;
line-height: 35px;
text-align: center;
font-feature-settings: 'liga' off;
color: #000000;
padding-bottom: 2rem;
}


.slider-wrapper {
	position: relative;
	max-width: 28rem;
	margin: 0 auto;
  
}

.slider {
	display: flex;
	aspect-ratio: 16 / 9;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
	box-shadow: 0 1.5rem 3rem -0.75rem hsla(0, 0%, 0%, 0.25);
	border-radius: 0.5rem;
    overflow-x: scroll;
}

.slider img {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
}
#img1 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img2 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img3 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img4 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img5 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img6 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img7 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
.slider-nav {
	display: flex;
	column-gap: 1rem;
	position: absolute;
	bottom: 1.25rem;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}

.slider-nav a {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 50%;
	background-color: #fff;
	opacity: 0.75;
	transition: opacity ease 250ms;
}

.slider-nav a:hover {
	opacity: 1;
}
#img1 .btn {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #00415A;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.stats{
	margin-top: 25px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.statsimage{
	width: 40%;
}
}




</style>