const Application = () => {
    return (
      <div style={{ maxWidth: "800px", margin: "0 auto", padding: "40px", fontFamily: "Arial, sans-serif", color: "#333" }}>
        <h1 style={{ fontSize: "2rem", fontWeight: "bold", textAlign: "center", marginBottom: "20px", color: "#2E8DEC" }}>Aggiungi PLTNVM alla schermata Home</h1>
        
        <p style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "center", marginBottom: "30px" }}>
          Le versioni per Apple Store e Google Play sono in arrivo! Nel frattempo, puoi avere <b>PLTNVM</b> sempre a portata di mano aggiungendolo alla schermata Home del tuo dispositivo.
        </p>
        
        <h2 style={{ fontSize: "1.5rem", marginBottom: "15px",textAlign: "center" }}>📱 Per dispositivi iOS</h2>
        <h3 style={{ fontSize: "1.2rem", marginBottom: "10px",textAlign: "center" }}>Se usi Safari:</h3>
        <ul style={{ fontSize: "1rem", lineHeight: "1.5", marginBottom: "20px",textAlign: "center" }}>
          <li>Vai su <a href="https://www.pltnvmexperience.com" target="_blank" rel="noopener noreferrer">www.pltnvmexperience.com</a></li>
          <li>Tocca l'icona di condivisione (il quadrato con la freccia verso l’alto)</li>
          <li>Seleziona <b>"Aggiungi alla schermata Home"</b></li>
          <li>Tocca <b>"Aggiungi"</b> e il gioco è fatto!</li>
        </ul>
        
        <h3 style={{ fontSize: "1.2rem", marginBottom: "10px",textAlign: "center" }}>Se usi Google Chrome:</h3>
        <ul style={{ fontSize: "1rem", lineHeight: "1.5", marginBottom: "30px" ,textAlign: "center"}}>
          <li>Vai su <a href="https://www.pltnvmexperience.com" target="_blank" rel="noopener noreferrer">www.pltnvmexperience.com</a></li>
          <li>Tocca l'icona con i tre puntini in basso a destra</li>
          <li>Seleziona <b>"Aggiungi alla schermata Home"</b></li>
          <li>Conferma toccando <b>"Aggiungi"</b></li>
        </ul>
        
        <h2 style={{ fontSize: "1.5rem", marginBottom: "15px",textAlign: "center" }}>🤖 Per dispositivi Android</h2>
        <h3 style={{ fontSize: "1.2rem", marginBottom: "10px",textAlign: "center" }}>Se usi Google Chrome:</h3>
        <ul style={{ fontSize: "1rem", lineHeight: "1.5", marginBottom: "20px",textAlign: "center" }}>
          <li>Vai su <a href="https://www.pltnvmexperience.com" target="_blank" rel="noopener noreferrer">www.pltnvmexperience.com</a></li>
          <li>Tocca l'icona con i tre puntini in alto a destra</li>
          <li>Seleziona <b>"Aggiungi a schermata Home"</b></li>
          <li>Conferma toccando <b>"Aggiungi"</b></li>
        </ul>
        
        <h3 style={{ fontSize: "1.2rem", marginBottom: "10px",textAlign: "center" }}>Se usi Microsoft Edge:</h3>
        <ul style={{ fontSize: "1rem", lineHeight: "1.5", marginBottom: "30px",textAlign: "center" }}>
          <li>Vai su <a href="https://www.pltnvmexperience.com" target="_blank" rel="noopener noreferrer">www.pltnvmexperience.com</a></li>
          <li>Tocca l'icona con i tre puntini in basso</li>
          <li>Seleziona <b>"Aggiungi a schermata Home"</b></li>
          <li>Conferma con <b>"Aggiungi"</b></li>
        </ul>
        
        <p style={{ fontSize: "1.1rem", fontWeight: "bold", textAlign: "center", marginBottom: "20px" }}>
          In questo modo potrai accedere a <b>PLTNVM</b> esattamente come un’app!
        </p>
        
        <p style={{ textAlign: "center", fontSize: "1rem" }}>Se hai domande o dubbi, scrivici a <a href="mailto:ceo@pltnvmexperience.com" style="color:blue;">ceo@pltnvmexperience.com</a>.</p>
        
        <p style={{ fontWeight: "bold", color: "#2E8DEC", textAlign: "center", fontSize: "1.2rem", marginTop: "20px" }}>🚀 Resta sintonizzato: l’app ufficiale su Apple Store e Google Play arriverà presto!</p>
      </div>
    );
  };
  
  export default Application;